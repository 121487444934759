<template>
  <Common
    :query="query"
    :read-num-list="readNumList"
    @createRandom="createRandom"
    @handleCreate="handleCreate"
    @handleImageUrl="handleImageUrl"
    @handleLink="handleLink"
    @hanldeRemove="hanldeRemove"
  />
</template>

<script>
  import mixins from './mixins'
  import { activityDetail } from '@/api/operate'
  export default {
    mixins: [mixins],
    data() {
      return {
        timeKey: [
          'create_time',
          'join_finish_time',
          'join_start_time',
          'publish_time',
          'rank_time',
          'recommend',
          'start_time',
          'finish_time',
        ],
        checkItem: [
          'phone',
          'name',
          'weixin',
          'qq',
          'remark',
          'remark_content',
        ],
      }
    },
    created() {
      if (this.$route.query.id) this.getInfoDetail()
    },
    methods: {
      async getInfoDetail() {
        const params = {
          id: this.$route.query.id,
        }
        const res = await activityDetail(params)
        if (res.success) {
          for (let key of Object.keys(this.query)) {
            if (this.timeKey.includes(key)) {
              this.query[key] = res.data[key] * 1000
            } else if (key === 'extra_info') {
              this.query[key] = JSON.parse(res.data[key])
            } else if (this.checkItem.includes(key)) {
              continue
            } else if (key === 'form_info') {
              let form_info = JSON.parse(res.data[key])
              for (let item of this.checkItem) {
                if (form_info[item]) {
                  if (item !== 'remark_content') {
                    this.query.form_info.push(item)
                  } else {
                    this.query[item] = this.query.form_info[item]
                  }
                }
              }
            } else {
              this.query[key] = res.data[key]
            }
          }
          // console.log(this.query)
        }
      },
    },
  }
</script>
