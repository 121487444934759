var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Common", {
    attrs: { query: _vm.query, "read-num-list": _vm.readNumList },
    on: {
      createRandom: _vm.createRandom,
      handleCreate: _vm.handleCreate,
      handleImageUrl: _vm.handleImageUrl,
      handleLink: _vm.handleLink,
      hanldeRemove: _vm.hanldeRemove,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }